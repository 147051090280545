import React from "react";
import propertyflag from "./../img/propertyflag.png"
import propertyflag2 from "./../img/propertyflag2.png"
import propertyflag3 from "./../img/propertyflag3.png"
import propertyimg from "./../img/propertyimg.png"
import propertyimg2 from "./../img/propertyimg2.png"
import propertyimg3 from "./../img/propertyimg3.png"
import "./Listing.css"
import ellipseleft from "./img/ellipseleft.png"

export default function Listing() {
  return (
    <React.Fragment>
      <div style={{
          backgroundImage: `url(${ellipseleft})`,
          backgroundRepeat: "no-repeat",
          // backgroundSize: "100%",
          backgroundPosition: "left top",
        }}>
      <section className="container mx-auto mt-40 mb-20 md:mt-80 md:mb-60">
        <div className="text-center">
          <h1 className="text-4xl text-[#0C2741] mb-10 font-semibold">
            Property Selection
          </h1>
          <p className="lg:mx-60">
            Benefit from a team of highly experienced investors, managers and
            professionals, targeting a high yield and capital growth strategy.
            Here are our new listings.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-8 xl:mx-20 px-0 sm:mx-40 md:mx-0 ">

          <PropertyCard img={propertyimg} flag={propertyflag} btnbg={"btn-bg-red"} titlebg={"listing-red"} bg={"bg-card-red"} soon={true} title={"39 Minnie St QLD"} price={"220"} gross={"4.7"} historic={"6.9"}/>
          <PropertyCard img={propertyimg} flag={propertyflag2} btnbg={"btn-bg-yellow"} titlebg={"listing-yellow"} bg={"bg-card-yellow"} soon={false} title={"Southport QLD"} price={"216"} gross={"4.7"} historic={"6.9"}/>
          <PropertyCard img={propertyimg} flag={propertyflag3} btnbg={"btn-bg-green"} titlebg={"listing-green"} bg={"bg-card-green"} soon={true} title={"Brisbane QLD"} price={"195"} gross={"6.5"} historic={"6.5"}/>
          <PropertyCard img={propertyimg} flag={propertyflag}btnbg={"btn-bg-blue"} titlebg={"listing-blue"} bg={"bg-card-blue"} soon={true} title={"Southport QLD"} price={"220"} gross={"4.7"} historic={"6.9"}/>
          <PropertyCard img={propertyimg} flag={propertyflag2} btnbg={"btn-bg-orange"} titlebg={"listing-orange"} bg={"bg-card-orange"} soon={true} title={"Southport QLD"} price={"220"} gross={"4.7"} historic={"6.9"}/>
          <PropertyCard img={propertyimg} flag={propertyflag3} btnbg={"btn-bg-aqua"} titlebg={"listing-aqua"} bg={"bg-card-aqua"} soon={true} title={"Southport QLD"} price={"220"} gross={"4.7"} historic={"6.9"}/>

        </div>
      </section>
      </div>
    </React.Fragment>
  );
}

const PropertyCard = ({ btnbg, titlebg, bg, soon, title, price, gross, historic, img, flag}) => {
  return (
    <React.Fragment>
      <div className={"shadow-lg relative border-1 border-gray rounded-[24px] rounded-tl-[24px] rounded-tr-[24px]" + bg}>
        {soon ? <div className="absolute h-full w-full rounded-tl-[24px] rounded-tr-[24px]" style={{ backdropFilter: "blur(6px)" }}>
        </div> : ""}

        <div className={"flex p-3 rounded-tl-[24px] rounded-tr-[24px] justify-between " + titlebg}>
          <p className="text-white font-semibold text-xl">{title}</p>
          <img src={flag} alt="property flag" />
        </div>
        <div className="w-full max-h-72 h-[200px] xl:h-[289px] overflow-hidden relative">
          <img src={img} alt="property image" className="w-full absolute bottom-0 rounded-b-[24px] " />
          {soon ?  <div className="absolute h-full w-full" style={{ backdropFilter: "blur(6px)" }}>
            <div className="flex justify-center mt-20">
              <p className="text-white text-[40px] font-bold items-center">Coming soon</p>
            </div>
          </div> : ""}
         
        </div>
        <div className="flex justify-between mx-4 mb-4">
          <div>
            <p className="font-medium text-md">Rental Yield (Gross)</p>
            <p className="font-semibold text-2xl">{gross}%</p>
          </div>
          <div>
            <p className="font-medium text-md">Price per NFT)</p>
            <p className="font-semibold text-2xl">${price}</p>
          </div>
        </div>

        <div className="flex justify-between mx-4 my-4">
          <div>
            <p className="font-medium text-md">Capital Gain (Historic)</p>
            <p className="font-semibold text-2xl">{historic}%</p>
          </div>
          <div>
            <button className={"rounded-[10px] px-8 py-2 text-white text-lg font-semibold " + btnbg}>Buy</button>
          </div>
        </div>

        <hr className="line-section mx-auto" style={{ marginTop: "7%" }} />

        <div className="flex justify-center my-4">
          <p className="text-black text-sm font-semibold cursor-pointer">View Details</p>
        </div>

      </div>
    </React.Fragment>
  )
}
