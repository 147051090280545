import React, { useEffect, useState } from "react";

import Navbar from "../../components/Navbar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import hero from "../../img/LandingPage/hero02b_60.jpg";
import botomHero from "../../img/LandingPage/hero02b_60grad.jpg";
import ethercards from "./assets/ethercards.png";
import transfermoney from "./assets/transfer_money.png";
import houses_bg from "./assets/houses_bg.png";
import buying_houses_1 from "./assets/buying_house_1.png";
import wallet from "./assets/wallet.png";
import house_1 from "./assets/house_1.png";
import filter_2 from "./assets/filter_2.png";
import businessman from "./assets/businessman.png";
import cube_2_1 from "./assets/cube_2_1.png";
import cube_2_2 from "./assets/cube_2_2.png";
import cube_2_3 from "./assets/cube_2_3.png";
import cube_1_1 from "./assets/cube_1_1.png";
import cube_1_2 from "./assets/cube_1_2.png";
import cube_1_3 from "./assets/cube_1_3.png";
import cube_1_5 from "./assets/cube_1_3.png";

export default function ExplainerPage() {
  return (
    <div>
      <Navbar />
      <section
        style={{
          backgroundImage: `url(${botomHero})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundPosition: "top, bottom",
        }}
      >
        <div className="container">
          <div className="row py-40">
            <div className="col-12 col-lg-8">
              <img src={ethercards} />
            </div>
            <div className="col-12 col-lg-4">
              <div className="display-3 fw-bold mt-10 text-center">
                <h1 className="">Property</h1>
                <h1>Investing</h1>
                <h1 style={{ color: "#0D67EE" }}>Simplified</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 my-4 d-none d-lg-block">
              <div className="relative">
                <img src={house_1} />
                <img
                  src={filter_2}
                  style={{
                    position: "absolute",
                    left: "370px",
                    top: "39px",
                  }}
                />
                <img
                  src={cube_1_1}
                  style={{
                    position: "absolute",
                    right: "168px",
                    top: "178px",
                  }}
                />
                <img
                  src={cube_1_2}
                  style={{
                    position: "absolute",
                    right: "107px",
                    top: "208px",
                  }}
                />
                <img
                  src={cube_1_3}
                  style={{
                    position: "absolute",
                    right: "53px",
                    top: "290px",
                  }}
                />
                <img
                  src={cube_1_5}
                  style={{
                    position: "absolute",
                    right: "-16px",
                    top: "364px",
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-lg-6 my-4">
              <h1 className="h2 text-center">LIST</h1>
              <div className="bg-light p-5 shadow-lg mx-auto rounded-xl">
                <dl className="row">
                  <dt className="col-sm-3 my-4">SELLER</dt>
                  <dd className="col-sm-9 my-4">List with an agent</dd>
                  <dt className="col-sm-3 my-4">BUYERS AGENT</dt>
                  <dd className="col-sm-9 my-4">
                    <p>Lists on PROPRT.io</p>
                    <p>Due Diligence Filter</p>
                    <p>Investment Grade only</p>
                    <p>Works for the best interest of the buyer</p>
                  </dd>
                  <dt className="col-sm-3 my-4">LEGAL</dt>
                  <dd className="col-sm-9 my-4">
                    <p>Ownership in real world in Trust</p>
                    <p>CCIV ownership structure</p>
                  </dd>
                </dl>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-6 my-4">
              <h1 className="h2 text-center">TRANSFER</h1>
              <div className="bg-light p-5 shadow-lg mx-auto rounded-xl">
                <dl className="row">
                  <dt className="col-sm-3 my-4">SELLER</dt>
                  <dd className="col-sm-9 my-4">
                    <p>List any number of NFTS</p>
                    <p>Set the ask price for each NFT</p>
                  </dd>
                  <dt className="col-sm-3 my-4">BUYER</dt>
                  <dd className="col-sm-9 my-4">
                    <p>Make an offer or pay ask price</p>
                    <p>Independent valuations available</p>
                  </dd>
                  <dt className="col-sm-3 my-4">TRANSFER</dt>
                  <dd className="col-sm-9 my-4">
                    <p>Purchase with Credit Card or web3 wallet</p>
                    <p>PROPRT NFT transferred to new owner</p>
                  </dd>
                  <dt className="col-sm-3 my-4">VERIFY</dt>
                  <dd className="col-sm-9 my-4">
                    <p>Verify ownership on Etherscan or opensea</p>
                    <p>Removal of "Trusted third parties"</p>
                  </dd>
                </dl>
              </div>
            </div>
            <div className="col-12 col-lg-6 my-4 d-none d-lg-block">
              <div className="relative">
                <img
                  src={cube_2_1}
                  style={{
                    position: "absolute",
                    left: "1px",
                    top: "1px",
                  }}
                />

                <img
                  src={cube_2_2}
                  style={{
                    position: "absolute",
                    left: "119px",
                    top: "78px",
                  }}
                />
                <img
                  src={cube_2_3}
                  style={{
                    position: "absolute",
                    left: "206px",
                    top: "71px",
                  }}
                />
                <img
                  src={businessman}
                  style={{
                    position: "absolute",
                    right: "100px",
                    top: "143px",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row my-32">
            <div className="col-12 col-lg-6 my-4 d-none d-lg-block">
              <div className="relative">
                <img src={buying_houses_1} />
                <img
                  src={wallet}
                  style={{
                    position: "absolute",
                    left: "372px",
                    top: "182px",
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-lg-6 my-4">
              <h1 className="h2 text-center">OWN PROPERTY</h1>
              <div className="bg-light p-5 shadow-lg mx-auto rounded-xl">
                <dl className="row">
                  <dt className="col-sm-3 my-4">RENT</dt>
                  <dd className="col-sm-9 my-4">
                    <p>Register to receive rental income</p>
                  </dd>
                  <dt className="col-sm-3 my-4">TENANCY</dt>
                  <dd className="col-sm-9 my-4">
                    <p>PROPRT owners can apply for tenancy priority</p>
                  </dd>
                  <dt className="col-sm-3 my-4">GROW</dt>
                  <dd className="col-sm-9 my-4">
                    <p>Increase propertyholdings over time</p>
                  </dd>
                  <dt className="col-sm-3 my-4">COMMUNITY</dt>
                  <dd className="col-sm-9 my-4">
                    <p>
                      Vote for asset through a DAO Decentralised Autonomous
                      Organisation
                    </p>
                  </dd>
                  <dt className="col-sm-3 my-4">OWNERSHIP</dt>
                  <dd className="col-sm-9 my-4">
                    <p>Assets verified on the Ethereum blockchain</p>
                  </dd>
                </dl>
              </div>
            </div>
          </div>

          <div className="row my-32">
            <div className="col-12 col-lg-6 my-4">
              <div className="bg-white p-2 shadow-lg rounded-xl text-center mt-32">
                <h1 className="h1 mt-4">
                  SELL <span className="text-[#0D67EE]">WHEN</span> YOU WANT
                </h1>
              </div>
            </div>
            <div className="col-12 col-lg-6 my-4">
              <div className="flex justify-center">
                <img src={transfermoney} className="text-center" />
              </div>
            </div>
          </div>
        </div>

        {/* <Header /> */}
        {/* {showModal ? popUp() : null} */}
      </section>
      <section
        style={{
          backgroundImage: `url(${houses_bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundPosition: "top, bottom",
        }}
      >
        <div className="container my-24">
          <div className="row">
            <div className="col xl:px-32">
              <h1 className="h1 text-center">PROPERTY MANAGEMENT</h1>
              <div className="bg-light p-5 shadow-lg mx-auto rounded-xl my-8">
                <div className="row gap-4">
                  <p>
                    Professional, experienced property agents manage all the
                    day-to-day operations.{" "}
                  </p>
                  <p>
                    Rent pays for expenses like repairs, rates, agents
                    fees...etc.
                  </p>
                  <p>
                    99% of the decisions that need to be made are done by the
                    agent and automated.{" "}
                  </p>
                  <p>
                    Every transaction is shown on the property dashboard for
                    full transparancey.{" "}
                  </p>
                  <p>
                    Each property has a small contingency fund included, which
                    forms part of the property value.
                  </p>
                  <p>
                    If a major expense is required, PROPRT owners can vote on
                    outcomes for each property.
                  </p>
                  <p>
                    A real-world back-up process allows for decisions in the
                    best interest of PROPRT holders.
                  </p>
                  <div className="row">
                    <dt className="col-sm-2 my-4">Coming Soon</dt>
                    <dd className="col-sm-10 my-4">
                      <p>
                        Financial products including borrowing, lending and
                        staking (DeFi)
                      </p>
                    </dd>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
