import React from "react";
import mailingimg from "../img/mailingimg.png";
import ellipseleft from "./img/ellipseleft.png"

export default function MailingList() {
  return (
    <React.Fragment>
       <div style={{
          backgroundImage: `url(${ellipseleft})`,
          backgroundRepeat: "no-repeat",
          // backgroundSize: "100%",
          backgroundPosition: "left",
        }}>
      <section>
        <div className="sm:container mx-auto rounded-lg xl:pt-16 relative xl:pb-40">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5 ms-5 me-5 align-content-center justify-content-center">
            <div className=" rounded-lg">
              <img
                className="mx-auto"
                src={mailingimg}
                alt="logo"
                onClick={() => {
                  alert("Coming soon");
                }}
              />
            </div>

            <div className="rounded-lg my-auto">
              <h1 className="text-4xl text-[#0C2741] mb-10 font-semibold">
                Join our mailing list to receive new listings and special
                offers.
              </h1>
              <div className="relative">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  placeholder="Enter your email"
                  className="mt-1 block w-full h-16 rounded-md border-gray-300 shadow-sm p-4  text-xs sm:text-sm"
                />
                <button className="absolute top-4 right-7 text-white bg-[#3489DB] px-4 py-2 rounded-md font-semibold  text-xs sm:text-sm">
                  Join
                </button>
              </div>
              <p className="mt-2">We will never share your email!</p>
            </div>
          </div>
        </div>
      </section>
      </div>
    </React.Fragment>
  );
}
