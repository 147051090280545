import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
// import hero from "../img/LandingPage/hero02b_60grad.jpg";
import logo from "../img/logo/proprt_logo_final.png";

import videoThumbnail from "../img/landingThumbnail.png";
import demoVideo from "../img/proprt vid final 021522.mp4";

import SendEmail from "./SendEmail";

import landingImage from "../img/landingimage.png";
import herobanner from "../img/herobanner.gif";

export default function Header() {
  const isPc = useMediaQuery({ query: "(min-width: 1225px)" });
  const isBigTablet = useMediaQuery({ minWidth: 1000, maxWidth: 1439 });
  const isTablet = useMediaQuery({ minWidth: 700.1, maxWidth: 1224 });
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  var [email, setEmail] = useState();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <div id="header" className="mx-auto pt-[7rem] lg:pt-[13rem]">
      {isMobile ? (
        <form
          className="mx-auto ps-3 pe-3 align-content-center justify-content-center"
          style={{ marginTop: "0rem" }}
          onSubmit={async (e) => {
            e.preventDefault();
            await fetch(
              "https://assets.mailerlite.com/jsonp/1318/forms/51049405316334751/subscribe",
              {
                method: "POST",
                body: JSON.stringify({
                  fields: {
                    email: email,
                  },
                }),
                headers: {
                  // Authorization: "c0f8c0d4f6c4f6f0f6f8f8f0f4f4f0f0",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then((data) => {
                console.log(data);
                if (data.success === false) {
                  console.log("error");
                  console.log(email);
                } else {
                  console.log("success");
                  console.log(email);
                }
              });
            alert(
              "Thank you. Confirmation email has been sent to you, please check your email."
            );
          }}
        >
          <h1 className="text-4xl md:text-7xl font-semibold text-[#0C2741] mt-20 md:mt-0">
            Micro Property Investing
          </h1>
          <p
            style={{ fontSize: "22px", fontFamily: "Montserrat" }}
            className="pt-4"
          >
            Own a piece of property from $200
          </p>
          <p
            style={{ fontSize: "20px", fontFamily: "Montserrat" }}
            className="p-0 fw-bold mb-3 mt-10"
          >
            Register for early access
          </p>
          <div className="col-span-6 sm:col-span-3 relative mb-20">
            <input
              type="text"
              name="first-name"
              id="first-name"
              placeholder="Enter your email address"
              className="mt-1 block w-full h-16 rounded-md border-gray-300 shadow-sm p-4 text-xs sm:text-sm"
            />
            <button className="absolute top-4 right-7 text-white bg-[#3489DB] px-4 py-2 rounded-md font-semibold text-xs sm:text-base">
              Sign up
            </button>
          </div>

          <div className="col-lg mx-auto justify-content-center align-items-center">
            {/* <img id='intro-video' src={videoPlaceHolder} style={{'width': '85%',}}/> */}
            {/* <HeaderPlayer /> */}
            <img className="mx-auto" src={herobanner} alt="landingImage" />
          </div>
        </form>
      ) : (
        <form
          // action="https://assets.mailerlite.com/jsonp/1318/forms/51049405316334751/subscribe"
          // method="POST"
          // target="_blank"
          className="row ms-5 me-5 align-content-center justify-content-center pe-5 ps-5"
          style={{ marginTop: "0rem" }}
          onSubmit={async (e) => {
            e.preventDefault();
            await fetch(
              "https://assets.mailerlite.com/jsonp/1318/forms/51049405316334751/subscribe",
              {
                method: "POST",
                body: JSON.stringify({
                  fields: {
                    email: email,
                  },
                }),
                headers: {
                  // Authorization: "c0f8c0d4f6c4f6f0f6f8f8f0f4f4f0f0",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then((data) => {
                console.log(data);
                if (data.success === false) {
                  console.log("error");
                  console.log(email);
                } else {
                  console.log("success");
                  console.log(email);
                }
              });
            alert(
              "Thank you. Confirmation email has been sent to you, please check your email."
            );
          }}
        >
          <div className="col-md pe-5 md:pl-20 xl:pl-40 md:mt-16">
            <h1 className="text-[80px] font-extrabold text-[#0C2741]">
              <span className="text-[#3489DB]">Micro</span> Property Investing
            </h1>
            <p
              style={{ fontSize: "22px", fontFamily: "Montserrat" }}
              className="pt-4"
            >
              Own a piece of property from $200
            </p>
            <div
              className="row mb-3 pe-5"
              style={{ margin: 0, padding: 0, marginTop: "3rem" }}
            >
              <p
                style={{ fontSize: "20px", fontFamily: "Montserrat" }}
                className="p-0 font-medium mb-3"
              >
                Register for early access
              </p>
              <div className="col-span-6 sm:col-span-3 p-0 relative w-[600px]">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  placeholder="Enter your email address"
                  className="mt-1 block w-full h-16 rounded-md border-gray-300 shadow-sm p-4 text-xs sm:text-sm"
                />
                <button className="absolute top-4 right-6 text-white bg-[#3489DB] px-4 py-2 rounded-md font-semibold text-xs sm:text-base">
                  Sign up
                </button>
              </div>
              <p
                style={{ fontSize: "20px", fontFamily: "Montserrat" }}
                className="pt-4 pl-0 font-medium"
              >
                Fractional Property ownership in minutes.{" "}
                <a
                  href="/faq"
                  className="text-[#0C2741] underline underline-offset-4"
                >
                  Find out More
                </a>
              </p>
            </div>
            {/* <p style={{'fontSize': '14px', marginTop: '80px', 'fontWeight': '650', color: '#2276C9'}}>Learn more about proprt</p> */}
          </div>
          <div className="col-lg mx-auto justify-content-center align-items-center  mt-12 lg:mt-0">
            {/* <HeaderPlayer /> */}
            <img className="mx-auto" src={herobanner} alt="landingImage" />
          </div>
        </form>
      )}
    </div>
  );
}

// https://www.youtube.com/embed/XvZBptrTB1g
