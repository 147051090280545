import React from "react";
import { MdOutlinePlayCircle } from "react-icons/md";

export default function Hero() {
  return (
    <div className="h-screen hero">
      <div className="grid grid-cols-2 gap-4 pt-60 container mx-auto">
        <div className="mx-auto">
          <div className="text-white">
            <h1 className="text-[40px] font-bold mt-20 mb-4">
              Property Investing Simplified
            </h1>
            <p className="text-[22px]">Crypto backed by property.</p>
            <p className="text-[22px]">
              Step onto the property ladder from $200.
            </p>
          </div>
          <div className="flex rounded-full bg-[#24334f80] w-3/4 p-2 mt-16">
            <input
              type="text"
              className="py-3 px-3 rounded-full w-3/5 mr-1"
              placeholder="Email"
            />
            <button
              type="button"
              className=" font-semibold  text-[#fff] bg-[#2276c9] py-3 px-3 rounded-full w-2/5 ml-1"
            >
              Register Interest
            </button>
          </div>
          <p className="text-[22px] text-white mt-2">
            …and yes, there will be Airdrops!
          </p>
          <div className="mt-8">
            <a
              target="_blank"
              href="https://www.youtube.com/embed/XvZBptrTB1g?si=nCV4mIDftxSiKcVj"
            >
              <div className="flex gap-2 ml-8">
                <MdOutlinePlayCircle className="text-4xl" />
                <p className="mt-1 ">Video Presentation</p>
              </div>
            </a>
          </div>
        </div>
        <div className="p-8 bg-[#6c000069] w-fit mx-auto">
          <p className="text-white text-[40px] text-center font-bold">
            Coming Q1 2024
          </p>
          <p className="text-[22px] text-white text-center mt-4">
            Extra rewards for early adopters.
          </p>
          <div className="bg-white w-full h-4 rounded-full mt-4"></div>
          <div className="flex justify-between">
            <p className="text-right text-[#94889a]">Seed</p>
            <p className="text-right text-white">2024 Q2.</p>
          </div>

          <p className="text-[22px] text-white text-center mt-8">
            5,000,000 Real Estate Tokens (REST)
          </p>
          <div className="flex justify-center my-4">
            <button
              type="button"
              className=" font-semibold  text-[#fff] bg-[#43C1A8] py-6 px-3 rounded-full w-full ml-1 hover:bg-[#2276c9]"
            >
              Buy Tokens
            </button>
          </div>
          <div className="flex justify-center my-4">
            <img
              src="https://ico.proprt.io/wp-content/uploads/2021/03/Payement.png"
              className="img-responsive"
              alt="Payement.png"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}
