import React from "react";
import logo from "../img/FMP-Logo.jpg2_.jpg";
import {
  FaFacebook,
  FaLinkedin,
  FaRegUserCircle,
  FaExternalLinkAlt,
} from "react-icons/fa";
import { HiMail } from "react-icons/hi";
// import { AiFillInstagram } from "react-icons/ai";
// import { BsMedium } from "react-icons/bs";
// import { SiMedium } from "react-icons/si";
// import img_real_estate from "../img/rea-logo-v4.png";
import img_qr from "./qr-code-12.png";
// import img_opensea from "../img/opensea.svg";
// import img_etherscan from "../img/etherscan-logo-light-circle.svg";
// import "animate.css";

// const handleVCFClick = (e) => {
//   e.preventDefault();
//   window.location.href = "/Frank_Teunissen.vcf";
// };

export default function ContactRonnie() {
  return (
    <div
      className=""
      style={{
        width: "full",
        // minHeight: "90vh",
        // height: "100vh",
        // overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background:
          "linear-gradient(180deg, rgba(23,30,74,100) 50%, rgba(238,125,35,100) 1000%)",
        padding: "50px",
        // backgroundColor: "#",
      }}
    >
      <div
        id="title"
        style={{
          // color: "#E78200",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
        className="mb-4"
      >
        {/* <p className="fs-4 text-white mb-0 pb-0 fw-bold">PROPRT.io</p> */}
        <img
          src={logo}
          alt="logo"
          // style={{
          //   filter: "drop-shadow(3px -1px 2.5px rgba(255, 255, 255, 0.67))",
          // }}
          className="h-24 mx-auto cursor-pointer animate__animated animate__slower 2s animate__pulse animate__infinite	infinite"
          onClick={() => {
            window.location.href = "/";
          }}
        />
      </div>
      <TheButton link={"https://www.facebook.com/findmypropert"}>
        <p className=" my-auto">
          Check our page in {` `}
          <FaFacebook
            className="inline h-6 my-auto"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open("https://www.facebook.com/findmypropert");
            }}
            size={30}
          />
        </p>
      </TheButton>
      <TheButton link={"http://www.linkedin.com/in/ronnie-hendriks-279a76231"}>
        <p className=" my-auto">
          Connect to our {` `}
          <FaLinkedin
            className="inline h-6 my-auto"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(
                "http://www.linkedin.com/in/ronnie-hendriks-279a76231"
              );
            }}
            size={30}
          />
        </p>
      </TheButton>

      <TheButton link={"/Ronnie-Hendriks.vcf"}>
        <p className=" my-auto">
          Save Contact {` `}
          <FaRegUserCircle
            className="inline mx-auto my-auto"
            style={{ cursor: "pointer" }}
            size={20}
          />
        </p>
      </TheButton>

      {/* <TheButton children={"Compare benefits"} link={"/benefits"} /> */}

      <TheButton link={"https://findmypropert.com.au/get-in-touch/"}>
        <p className=" my-auto">
          Get in touch {` `}
          <HiMail
            className="inline mx-auto my-auto"
            style={{ cursor: "pointer" }}
            size={23}
          />
        </p>
      </TheButton>
      <TheButton link={"https://findmypropert.com.au/"}>
        <p className=" my-auto">
          Check our website {` `}
          <FaExternalLinkAlt
            className="inline h-6 my-auto"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open("https://www.facebook.com/findmypropert");
            }}
            size={17}
          />
        </p>
      </TheButton>

      <p id="motto" className="text-white text-center mt-4">
        “You acted as if it was your own money”
      </p>
      {/* <div className="d-flex justify-content-between mt-3 gap-3 text-white">
        <FaDiscord
          className="md:w-8 w-6 m-1 h-auto  flex-auto"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open("https://discord.gg/MgCe6Gzk2W");
          }}
          size={30}
        />
        <FaTwitter
          className="md:w-8 w-6 m-1 h-auto  flex-auto"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open("https://twitter.com/proprt_io");
          }}
          size={30}
        />
        <FaFacebook
          className="md:w-8 w-6 m-1 h-auto  flex-auto"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open("https://www.facebook.com/Proprtio-113952484660475");
          }}
          size={30}
        />
        <AiFillInstagram
          className="md:w-8 w-6 m-1 h-auto  flex-auto"
          style={{ cursor: "pointer" }}
          size={30}
          onClick={() => {
            window.open("https://www.instagram.com/proprt.io/");
          }}
        />
        <FaLinkedin
          className="md:w-8 w-6 m-1 h-auto  flex-auto"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open("https://www.linkedin.com/company/proprt-io/");
          }}
          size={30}
        />
        <BsMedium
          className="md:w-8 w-6 m-1 h-auto  flex-auto"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(
              "https://medium.com/coinmonks/step-onto-the-property-ladder-29aa2d803cc4"
            );
          }}
          size={30}
        />
        <img
          src={img_etherscan}
          alt="etherscan"
          className="md:w-8 w-6 m-1 h-auto  flex-auto"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(
              "https://etherscan.io/token/0x978b22086fe90ffdfc509f30753c9f2211bb1de9?a=0x5169b464aaaa61506946bb54ba1d5dda883981ff"
            );
          }}
          width={30}
          color="white"
        />
      </div> */}

      <img
        src={img_qr}
        alt="qr code"
        className="h-52 mt-14 mx-auto cursor-pointer animate__animated animate__slower 2s animate__pulse animate__infinite	infinite shadow-2xl rounded-3xl"
        // onClick={() => {
        //   window.location.href = "/";
        // }}
      />
    </div>
  );
}

const TheButton = ({ link, children, logo, animate }) => {
  return (
    <button
      className={
        "card my-3 p-3 border-0 bg-[#EE7D23] shadow-2xl h-14 max-h-14 text-white hover:scale-105 duration-300 hover:text-white rounded-md " +
        animate
      }
      style={{ width: "75vw", maxWidth: "500px" }}
      onClick={() => {
        window.location.href = link;
      }}
    >
      <div className="flex justify-between w-full content-center">
        <div>{logo && <img src={logo} alt="" className=" w-8 h-8" />}</div>
        <div>
          <p
            className=" my-auto font-semibold mx-auto md:text-base text-sm"
            // style={{
            //   color: "#2276C9",
            // }}
          >
            {children}
          </p>
        </div>
        <div className=""></div>
      </div>
    </button>
  );
};
