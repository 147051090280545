import React from "react";
import blog1 from "../img/blog1.png";
import blog2 from "../img/blog2.png";

export default function Blog() {
  return (
    <React.Fragment>
      <div className="my-40 md:my-60">
      <div
        className="container text-center mt-5 pt-5 pb-5"
        style={{ width: "55vw" }}
      >
        <p className="text-center text-4xl text-[#0C2741] font-semibold">
          As seen on
        </p>
      </div>
      <section className="text-gray-600 body-font">
        <div className="container px-5 mx-auto">
          <div className="flex flex-wrap -m-4  justify-center gap-8">
            <img
              className="h-8 md:h-12 border-opacity-60 rounded-lg overflow-hidden hover:cursor-pointer hover:scale-110 transform transition duration-500 ease-in-out"
              src={
                "https://s1.rui.au.reastatic.net/rui-static/img/rea-logo-v4.png"
              }
              alt="blog"
              onClick={() => {
                window.open(
                  "https://www.realestate.com.au/news/buy-a-piece-of-a-luxury-gold-coast-property-from-220/",
                  "_blank"
                );
              }}
            />
            <img
              className="h-8 md:h-12 border-opacity-60 rounded-lg overflow-hidden hover:cursor-pointer hover:scale-110 transform transition duration-500 ease-in-out"
              src={
                "https://miro.medium.com/max/8978/1*s986xIGqhfsN8U--09_AdA.png"
              }
              alt="blog"
              onClick={() => {
                window.open(
                  "https://medium.com/coinmonks/step-onto-the-property-ladder-29aa2d803cc4",
                  "_blank"
                );
              }}
            />
            <img
              className="h-8 md:h-12 border-opacity-60 rounded-lg overflow-hidden hover:cursor-pointer hover:scale-110 transform transition duration-500 ease-in-out"
              src={
                "https://upload.wikimedia.org/wikipedia/commons/e/e7/Daily_Telegraph.svg"
              }
              alt="blog"
              onClick={() => {
                window.open(
                  "https://www.dailytelegraph.com.au/real-estate/queensland/gold-coast/buy-a-piece-of-a-luxury-gold-coast-property-from-220/news-story/9e2f7c60ef0400526eda04299fb09543?rsf=syn:news:nca:dt:spa",
                  "_blank"
                );
              }}
            />
          </div>
        </div>
      </section>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-12 md:container mx-auto my-20">
        <BlogCard
          img={blog1}
          title="Buy a piece of property"
          desc="Simplified Property Investing"
          link="View now..."
          url="https://www.youtube.com/watch?v=XvZBptrTB1g"
        />
        <BlogCard
          img={blog2}
          title="Buy a piece of Luxury Gold Coast Real Estate ..."
          desc="Step onto the property ladder from as little as $200. Fractional Property ownership verified on Ethereum."
          link="Read more..."
          url="https://www.realestate.com.au/news/buy-a-piece-of-a-luxury-gold-coast-property-from-220/"
        />
      </div>
      </div>
    </React.Fragment>
  );
}

const BlogCard = ({ img, title, desc, link, url }) => {
  return (
    <React.Fragment>
      {/* <div className="flex gap-4">
        <div className="basis-1/2 lg:basis-1/4">
          <img src={img} className="h-36 w-full" alt="" />
        </div>
        <div className="basis-1/2">
          <h1 className="text-xl text-[#0C2741] font-semibold">{title}</h1>
          <p className="text-md text-[#0C2741]">{desc}</p>
          <p>
            <span className="text-[#3489DB] text-md cursor-pointer">
              {link}
            </span>
          </p>
        </div>
      </div> */}

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 place-content-start">
        <a href={url} target="_blank">
        <div className="rounded-lg h-[220px]"
         style={{
          backgroundImage: `url(${img})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundPosition: "center",
        }}> </div>
        </a>
        <div> <h1 className="text-xl text-[#0C2741] font-semibold">{title}</h1>
          <p className="text-md text-[#0C2741]">{desc}</p>
          <p>
            <a className="text-[#3489DB] text-md cursor-pointer" href={url} target="_blank">
              {link}
            </a>
          </p></div>
      </div>
    </React.Fragment>
  );
};
