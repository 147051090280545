import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import hero from "../../img/LandingPage/hero02b_60grad.jpg";
import { Link } from "react-scroll";
import blog_1 from "./undraw_buy_house_re_8xq7-2.svg";
import blog_uce from "./../HelpCenter/pictures/1.png";
import blog_coming from "./undraw_coming_home_re_ausc.svg";

export default function FaqPage() {

    const [articles, setArticles] = useState("");
    const url = "https://api1.proprt.io/api/resource-groups?fields=name&populate[resources][fields]=title,article&sort=priority";
    const[title,setTitle] = useState("");
    const[desc,setDesc] = useState("");

    useEffect(() => {
    const fetchData = async () => {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + "0790bb8a25e9debebde09d84882191c4f57073d9c1039a7b00cd1d9e304490a874de3774f698deb7ee5113320ffaeb50088b16e56f4f956dd844862686560293b90ad8efef74f4fa56d3b5be4e303335ebd4261c3c807a7adefabd2d21fd45b9f8481fc17a6a070c51e99c96fb27babca20dc89e2b60123edf99f30d01cc77a6",
            },
          });
          const json = await response.json();
          setArticles(json.data);
          const tempArticle = json.data[0].attributes.resources.data[0].attributes;
          setTitle(tempArticle.title);
          setDesc(tempArticle.article);
         
        } catch (error) {
          console.log("error", error);
        }
      };
      fetchData();
    }, []);

    function clickArticle([title, description]) {
      setTitle(title);
      if (!description) {
        Array.from(articles).map(({ attributes, id }) => {   
          for (var i = 0; i < attributes.resources.data.length; i++) {
        
            if((attributes.resources.data[i].attributes.title == title)) {
              setDesc((attributes.resources.data[i].attributes.article))
            }
          }
         
        });
      } else {
        setDesc(description);
      }
      window.scrollTo(0, 0)
    }

    const groupArticleList = Array.from(articles).map(({ attributes, id }) => {
        return (
            <div className="mb-5" id={"group-" + id}>
                <div className="">
                    <h2 className="text-2xl" style={{ color: "#2276C9", fontWeight: "bold" }}>{attributes.name}</h2>
                </div>

                {Array.from(attributes.resources.data).map(
                    ({ attributes, id }) => {
                      let border = "";
                      if(attributes.title == title) {
                        border = "border-2";
                      } else {
                        border = "";
                      }
                        return (
                          <div className={"my-4 cursor-pointer hover:border-2 p-2 rounded-xl border-[#2276C9] "+ border} id={"article-" +id}>
                            <Link
                              to={`${"title-article-"+ id}`}
                              spy={true}
                              smooth={true}
                              offset={-120}
                              className="sidebar"
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                clickArticle([attributes.title, attributes.article])
                              }
                            >
                              <h5 style={{ color: "#2276C9" }}>{attributes.title}</h5>
                            </Link>
                          </div>
                           
                        );
                    }
                )}
            </div>
        )
    });



    const articleListDropdown = Array.from(articles).map(({ attributes, id }) => {

    
      return (
          // <div className="mb-5" id={"group-" + id}>
            <React.Fragment>
              {/* <div className="">
                  <h2 style={{ color: "#2276C9", fontWeight: "bold" }}>{attributes.name}</h2>
              </div> */}

              {Array.from(attributes.resources.data).map(
                  ({ attributes, id }) => {
                      return (
                       <option
                          value={attributes.title}
                          key={id}
                        >
                          {attributes.title}
                        </option>
                         
                      );
                  }
              )}
          </React.Fragment>
    
      )
  });


  return (
   <>
    <Helmet>
        <title>Help</title>
        <meta name="description" content="How it works" />
        <meta name="theme-color" content="#2276C9" />
      </Helmet>
      <Navbar />
      <div className="container-fluid" >
        <div className="row" style={{
                backgroundImage: `url(${hero})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}>
            <h1
              style={{
                marginTop: "10rem",
                marginLeft: "8vw",
                marginBottom: "5rem",
                fontWeight: "bold",
                color: "#113D63",
              }}
              className="text-xl md:text-4xl"
            >
              Frequently Asked Questions
            </h1>
        </div>
        
      </div>
      <div className="container">
        <div className="row">
           
            <div className="col-md-2 py-12">
                <div className="overflow-auto">
                <div className="hidden md:block xl:block">
                {groupArticleList}
                </div>
                <div className="p-4 block md:hidden xl:hidden">
                  <select
                    name="articlelist"
                    id="articlelist"
                    onChange={(e) => clickArticle([e.target.value])}
                    className="w-full rounded-md border p-2"
                  >
                    {articleListDropdown}
                  </select>
                </div>
                </div>
            </div>
           
            <div className="col-md-5 offset-md-1 py-12 px-8 md:px-0">
              <div className="mb-5 pb-5">
                <h5 className="cursor-pointer text-xl font-semibold my-2" style={{ color: "#2276C9" }}>{title}</h5>
                <p className="my-8" style={{ lineHeight: 2.5 }}>{desc}</p>
              </div>
            </div>

            <div className="col-md-2 offset-md-2 px-8 md:px-0">

            <h1 className="text-xl my-8 text-[#113D63] font-bold">
             Blog
            </h1>

              <div className="card w-full my-4">
                <a
                  href="https://medium.com/@frank_18938/step-onto-the-property-ladder-29aa2d803cc4"
                  className="blog-embed hover:text-white"
                  style={{ padding: 0 }}
                >
                  <img className="card-img-top h-full" src={blog_1} alt="Card image cap"/>
                  <div className="card-body">
                    <p className="card-text"> In the last 10 years, house prices have grown 3 times
                        faster than wages!</p>
                  </div>
                </a>
              </div>

              <div className="card w-full my-4">
                <a
                  href="https://medium.com/@frank_18938/the-uce-case-for-nfts-3711b56e784e"
                  className="blog-embed hover:text-white"
                  style={{ padding: 0 }}
                >
                  <img className="card-img-top h-full" src={blog_uce} alt="Card image cap"/>
                  <div className="card-body">
                    <p className="card-text"> In the last 10 years, house prices have grown 3 times
                        faster than wages!</p>
                  </div>
                </a>
              </div>

              <div className="card w-full my-4">
                <a
                  href="https://www.realestate.com.au/news/buy-a-piece-of-a-luxury-gold-coast-property-from-220/?rsf=syn:news:nca:dt:spa"
                  className="blog-embed hover:text-white"
                  style={{ padding: 0 }}
                >
                  <img className="card-img-top h-full" src={"https://www.realestate.com.au/news/wp-content/uploads/2022/09/capi_9e2f7c60ef0400526eda04299fb09543_d261159d3ba13fc80472a7d13c521cfe.jpeg"} alt="Card image cap"/>
                  <div className="card-body">
                    <p className="card-text"> In the last 10 years, house prices have grown 3 times
                        faster than wages!</p>
                  </div>
                </a>
              </div>

              <div className="card w-full my-4">
                <a
                  href="#"
                  className="blog-embed hover:text-white"
                  style={{ padding: 0 }}
                >
                  <img className="card-img-top h-full" src={blog_coming} alt="Card image cap"/>
                  <div className="card-body">
                    <p className="card-text"> In the last 10 years, house prices have grown 3 times
                        faster than wages!</p>
                  </div>
                </a>
              </div>
              
            </div>
        </div>
      </div>
      <Footer />
   </>
  )
}
